/* eslint-disable max-len */
import * as React from 'react';
import { Layout } from 'components/Layout';
import { SEO } from 'components/SEO';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { YandexMap } from 'components/YandexMap';
import { PhoneLink } from 'components/PhoneLink';
import s from './contacts.module.css';

const ContactsPage = () => {
  const onLoad = (map: ymaps.Map, ymaps: any) => {
    map.setZoom(18);

    const deliveryPointPlacemark = new ymaps.Placemark(map.getCenter(), {
      hintContent: 'TreeRipe',
    });

    map.geoObjects.add(deliveryPointPlacemark);
  };

  return (
    <Layout>
      <SEO title="Контакты" />
      <div className="tr-bg-gray-2 py-20 mb-12 mlg:py-12 mmd:py-10 mmd:mb-10 msm:py-8 msm:mb-8">
        <div className="container flex flex-col items-center text-center">
          <h1 className={s.heroTitle}>Вам интересны наши продукты? Запросите пробную поставку</h1>
          <div className={s.heroDescription}>
            Если вы хотите быть в курсе наших инноваций и рекламных кампаний, которые помогают нашим клиентам продавать
            больше манго и больше авокадо, свяжитесь с нами, чтобы узнать о возможностях плодотворного сотрудничества.
            Мы будем рады посетить вас - с нашими манго и авокадо, конечно же, - для ознакомительной встречи и
            вдохновляющей сессии.
          </div>
        </div>
      </div>
      <Breadcrumbs label="Контакты" className="mb-16 mlg:mb-12 mmd:mb-10 msm:mb-8" />
      <div className="container">
        <div className="grid grid-cols-3 gap-8 mb-8 msm:grid-cols-1 msm:gap-2">
          <div className={s.infoItem}>
            <div className="font-bold mb-6 mmd:mb-4">Компания</div>
            <div className="mb-2">TreeRipe</div>
            ООО Трирайп
          </div>
          <div className={s.infoItem}>
            <div className="font-bold mb-6 mmd:mb-4">Реквизиты</div>
            <div className="mb-2">ИНН: 7730252388</div>
            КПП: 773001001
          </div>
          <div className={s.infoItem}>
            <div className="font-bold mb-6 mmd:mb-4">Связаться с нами</div>
            <PhoneLink showMorePhones className="block mb-2 hover:opacity-80" />
            <a href="mailto:info@treeripe.ru" className="hover:opacity-80">
              info@treeripe.ru
            </a>
          </div>
        </div>
        <div className={s.mapWrapper}>
          <div className="flex items-center h-20 text-lg leading-6 px-8 mmd:h-auto mmd:text-sm mmd:leading-4 mmd:p-4 msm:flex-col msm:items-start">
            <b className="font-bold mr-1 msm:mr-0 msm:mb-4">Адрес:</b> г. Москва, ул. Рябиновая, д. 45
          </div>
          <YandexMap center={{ lat: 55.69762, lng: 37.424138 }} onLoad={onLoad} className={s.map} />
        </div>
      </div>
    </Layout>
  );
};

export default ContactsPage;
