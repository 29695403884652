import * as React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import ChevronRight from './icons/ChevronRight';

type Props = {
  readonly label: string;
  readonly className?: string;
};

export const Breadcrumbs = ({ className, label }: Props) => (
  <div className={cn('container', className)}>
    <div className="flex items-center pb-4 border-b tr-border-gray-1">
      <Link
        to="/"
        className="text-sm leading-5 opacity-75 transition-opacity duration-300 hover:opacity-100 focus:opacity-100"
      >
        Главная
      </Link>
      <ChevronRight className="w-5 h-5 mx-4" />
      <div className="text-sm leading-5">{label}</div>
    </div>
  </div>
);

Breadcrumbs.defaultProps = {
  className: undefined,
};
